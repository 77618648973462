import { useNavigate } from '@tanstack/react-location'
import classnames from 'classnames'
import { type FunctionComponent, useEffect, useState } from 'react'
import { CuiButton, CuiFlexGroup, CuiPad, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import { ReactComponent as CartaLogo } from 'src/images/logos/logo.svg'
import { useIsMobile } from 'src/utils/mediaQuery'
import styled from 'styled-components'

type CuiStaticNavbarStyle = 'light' | 'accent' | 'gradient'

type Props = {
  style: CuiStaticNavbarStyle
}

type StaticNavbarRootProps = { $style: CuiStaticNavbarStyle }

export const STATIC_NAVBAR_HEIGHT = '72px'

const getBackground = ($style: CuiStaticNavbarStyle) => {
  if ($style === 'light') {
    return palette.white
  }

  return palette.brandInteractive60
}

// note that a gradient background is needed to contain the navbar and following elements, this is why the navbar is transparent
// so that the gradient will be seamless across navbar + other elements
const StaticNavbarRoot = styled.div<StaticNavbarRootProps>`
  position: sticky;
  top: 0;
  height: ${STATIC_NAVBAR_HEIGHT};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ $style }) => ($style === 'light' ? palette.neutral90 : palette.white)};

  background: ${({ $style }) => ($style === 'gradient' ? 'transparent' : getBackground($style))};

  &.StaticNavbarRoot--isScrolled {
    box-shadow: 0px 1.5px 12px rgba(0, 0, 0, 0.2);

    ${({ $style }) =>
      $style === 'gradient' &&
      `background: linear-gradient(66deg, ${palette.brandMain70} -35.27%, #b71c1c 87.78%);`}
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;

  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }

  padding: 0 12px;
`

const PagesTab = styled(CuiButton)`
  border: none;
  background: transparent;
  padding: 10px;
`

export const StaticNavbar: FunctionComponent<React.PropsWithChildren<Props>> = ({ style }) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const isAtTopOfWindow = useIsAtTopOfWindow()

  return (
    <StaticNavbarRoot
      $style={style}
      className={classnames({
        'StaticNavbarRoot--isScrolled': isAtTopOfWindow === false,
      })}
    >
      <div style={{ width: '100%' }}>
        <CuiPad horizontalSize={isMobile ? 'xs' : 'm'}>
          <CuiFlexGroup justifyContent='spaceBetween'>
            <div style={{ cursor: 'pointer' }}>
              <CartaLogo onClick={() => navigate({ to: '/landing' })} />
            </div>
            <CuiFlexGroup justifyContent='spaceBetween' gutterSize='s'>
              <PagesTab as={StyledLink} href='/about'>
                <CuiText color='inherit' size='title3'>
                  About Us
                </CuiText>
              </PagesTab>
              <PagesTab as={StyledLink} href='/apply'>
                <CuiText color='inherit' size='title3'>
                  Join Our Team
                </CuiText>
              </PagesTab>
              <div
                style={{
                  border: style === 'accent' || style === 'gradient' ? '1px solid white' : 'none',
                  borderRadius: style === 'accent' || style === 'gradient' ? '4px' : '0',
                }}
              >
                <CuiButton
                  color='accent'
                  onClick={() => {
                    const target = `${process.env.REACT_APP_BACKEND_URL}/Auth/Redirect?returnUrl=${encodeURIComponent(window.location.origin)}`
                    window.location.href = target
                  }}
                >
                  <CuiText size='title3' color='textOnDarkBackground'>
                    Login
                  </CuiText>
                </CuiButton>
              </div>
            </CuiFlexGroup>
          </CuiFlexGroup>
        </CuiPad>
      </div>
    </StaticNavbarRoot>
  )
}

const SCROLL_HEIGHT = 20

function useIsAtTopOfWindow() {
  const [isAtTop, setIsAtTop] = useState(window.scrollY < SCROLL_HEIGHT)

  useEffect(() => {
    const onScroll = () => {
      setIsAtTop(window.scrollY < SCROLL_HEIGHT)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return isAtTop
}
