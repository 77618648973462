import { Link } from '@tanstack/react-location'
import classnames from 'classnames'
import { useCombobox } from 'downshift'
import { Fragment, type KeyboardEvent } from 'react'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiIcon, type CuiIconType } from '../CuiIcon'
import { CuiInput } from '../CuiInput'
import { CuiPad } from '../CuiPad'
import { CuiText } from '../CuiText'

const CuiIconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`

const CuiSearchWrapper = styled.div`
  position: relative;
  width: 60%;
  max-width: 600px;
  min-width: 50px;
`

const StyledCuiLink = styled(Link)`
  text-decoration: none;
`

type SearchResultItemProps = {
  /**
   *
   * Icon associated with the search result
   */
  icon?: CuiIconType
  /**
   *
   * title of the item to display
   */
  title: string
  /**
   *
   *
   */
  link?: string

  /**
   *
   * If the search result is empty
   */
  isEmpty?: boolean
}

const SearchResultItem = ({ icon, title, link, isEmpty }: SearchResultItemProps) => {
  const resultItem = (
    <CuiPad horizontalSize='s' verticalSize={isEmpty ? 's' : undefined}>
      <CuiFlexGroup alignItems='center'>
        {icon && (
          <CuiIconWrapper>
            <CuiIcon type={icon} color='text' />
          </CuiIconWrapper>
        )}
        <CuiText>{title}</CuiText>
      </CuiFlexGroup>
    </CuiPad>
  )

  return <Fragment>{link ? <StyledCuiLink to={link}>{resultItem}</StyledCuiLink> : resultItem}</Fragment>
}

type SearchResultsListProps = {}

const SearchResultsList = styled.ul<SearchResultsListProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.cuiColors.background};
  border: 1px solid ${({ theme }) => theme.cuiColors.lightShade};
  border-radius: 4px;
  max-height: 352px;
  overflow-y: scroll;
  position: absolute;
  margin-top: 8px;
  width: 100%;
  z-index: ${({ theme }) => theme.cuiStackingLevels.dropdown};

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  display: none;

  &.SearchResultsList--isOpen {
    display: block;
  }
`

type SearchResultsListItemProps = {
  $selected: boolean
}

const SearchResultsListItem = styled.li<SearchResultsListItemProps>`
  color: ${({ theme }) => theme.cuiColors.lightestText};
  background-color: ${({ theme, $selected }) => ($selected ? theme.cuiColors.overlayHover01 : 'none')};
  padding: 8px 0;
  cursor: pointer;
`

type CuiSearchProps<TItem> = {
  /**
   *
   * on click search or press enter callback
   */
  onSearch: (value: string) => void

  /**
   *
   * on text change callback
   */
  onChange: (value: string) => void

  /**
   *
   * on click search result call back
   */
  onResultClick: (item: TItem) => void

  /**
   *
   * value of search bar
   */
  value: string

  /**
   *
   * placeholder of search bar
   */
  placeholder?: string

  /**
   *
   * data of search results
   */
  items: TItem[]

  /**
   *
   * getter function for the label
   */
  getItemTitle: (item: TItem) => string

  /**
   *
   *
   */
  getItemIcon: (item: TItem) => CuiIconType

  /**
   *
   * getter function for the item's href
   */
  getItemHref?: (item: TItem) => string

  /**
   *
   * Message for when there are no results
   */
  emptyResultsMessage?: string
}

export const CuiSearch = <TItem,>({
  onSearch,
  onChange,
  onResultClick,
  value,
  placeholder,
  items,
  getItemTitle,
  getItemHref,
  getItemIcon,
  emptyResultsMessage,
}: CuiSearchProps<TItem>) => {
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    setInputValue,
  } = useCombobox({
    items,
    itemToString: (item) => (item ? getItemTitle(item) : ''),
    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue ?? '')
    },
  })

  const handleResultClick = (item: TItem) => {
    onResultClick(item)
    setInputValue(getItemTitle(item))
    closeMenu()

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }

  const {
    onChange: downshiftOnChange,
    onKeyDown: downshiftOnKeyDown,
    ...restInputProps
  } = getInputProps({
    onFocus: () => {
      if (!isOpen || items.length > 0) {
        openMenu()
      }
    },
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return downshiftOnKeyDown?.(event)
    }

    closeMenu()

    if (highlightedIndex === -1) {
      onSearch(event.currentTarget.value)

      return
    }

    onResultClick(items[highlightedIndex])
  }

  return (
    <CuiSearchWrapper>
      <div>
        <CuiInput
          onChange={onChange}
          iconType='search'
          placeholder={placeholder}
          _nativeOnChange={downshiftOnChange}
          {...restInputProps}
          value={value}
          onKeyDown={handleKeyDown}
        />
      </div>
      <SearchResultsList
        {...getMenuProps()}
        className={classnames({
          'SearchResultsList--isOpen': isOpen,
        })}
      >
        {items.length > 0 ? (
          items.map((item, index) => (
            <SearchResultsListItem
              {...getItemProps({
                item,
                index,
                onClick: (event) => {
                  // @ts-ignore
                  event.nativeEvent.preventDownshiftDefault = true
                  onResultClick(items[highlightedIndex])
                  handleResultClick(item)
                },
              })}
              $selected={index === highlightedIndex}
              key={`${item}${index}`}
            >
              <SearchResultItem
                title={getItemTitle(item)}
                icon={getItemIcon(item)}
                link={getItemHref?.(item)}
              />
            </SearchResultsListItem>
          ))
        ) : (
          <SearchResultItem title={emptyResultsMessage ?? 'No results found'} isEmpty={true} />
        )}
      </SearchResultsList>
    </CuiSearchWrapper>
  )
}
