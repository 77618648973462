import * as RadixPopover from '@radix-ui/react-popover'
import { Fragment, useState } from 'react'
import styled from 'styled-components'

import {
  CuiBadge,
  CuiButton,
  CuiCheckbox,
  CuiFilterButton,
  CuiFlexGroup,
  CuiIcon,
  CuiInput,
  CuiLink,
  CuiPad,
  CuiSpacer,
  CuiText,
} from '../../components'
import { CuiFilterRoot, CuiFilterTrigger } from '../CuiFilter'

type ItemGroup<TItemSingle> = {
  label: string
  items: TItemSingle[]
}

type Item<TItemSingle> = TItemSingle | ItemGroup<TItemSingle>

interface Props<TItem> {
  /**
   *
   * Optional custom styling for the label using CuiText
   */
  labelTextProps?: Partial<React.ComponentProps<typeof CuiText>>

  /**
   *
   * Optional icon to display next to the label using CuiIcon
   */
  labelIconProps?: Partial<React.ComponentProps<typeof CuiIcon>>

  /**
   *
   * items present in the filter selection
   */
  items: Item<TItem>[]

  /**
   *
   * label on the filter dropdown
   */
  label: string

  /**
   *
   * selected items in the Filter
   */
  selectedItems: TItem[]

  /**
   *
   * placeholder string in search bar
   */
  placeholder?: string

  /**
   *
   * Message for when there are no results
   *
   * @default "No results found"
   */
  emptyResultsMessage?: string

  /**
   *
   * on text change callback
   */
  onTextChange: (value: string) => void

  /**
   *
   * callback for updating the selected items
   */
  setSelectedItems: (selectedItems: TItem[]) => void

  /**
   *
   * Get the item label for an item that is singular (non-grouped)
   */
  getItemLabel: (item: TItem) => string

  /**
   *
   * get key for each item
   */
  getItemKey: (item: TItem) => string
}

const CuiFilterItem = styled.div`
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover01};
  }
`

const CuiFilterScrollContainer = styled.div`
  max-height: 350px;
  overflow: scroll;
`

const CuiSelectedBadge = styled.div`
  cursor: pointer;
`

const CuiFilterSearchPopoverRoot = styled(RadixPopover.Root)`
  z-index: ${({ theme }) => theme.cuiStackingLevels.dropdown};
`
const SelectedItemsContainer = styled.div`
  max-height: 100px;
  overflow-y: auto;
  min-width: 240px;
  max-width: 240px;
`

export const CuiFilterSearch = <TItem extends {}>({
  items,
  label,
  selectedItems,
  placeholder,
  emptyResultsMessage = 'No results found',
  onTextChange,
  setSelectedItems,
  getItemLabel,
  getItemKey,
  labelTextProps,
  labelIconProps,
}: Props<TItem>) => {
  const [searchValue, setSearchValue] = useState('')
  const [localSelected, setLocalSelected] = useState(selectedItems)

  const isItemsSelected = selectedItems.length > 0

  return (
    <CuiFilterSearchPopoverRoot
      onOpenChange={(open) => {
        if (!open) {
          setSearchValue('')
        } else {
          setLocalSelected(selectedItems)
        }
      }}
    >
      <CuiFilterTrigger>
        <CuiFilterButton iconSide='right' iconType='caret-down' selected={isItemsSelected}>
          {getButtonLabel(labelTextProps, labelIconProps)}
        </CuiFilterButton>
      </CuiFilterTrigger>
      <CuiFilterRoot align='end'>
        <CuiPad topSize='xs'>
          <CuiPad horizontalSize='s'>
            <SelectedItemsContainer>
              <CuiFlexGroup gutterSize='xs' wrap={true}>
                {localSelected.map((selected) => (
                  <CuiSelectedBadge
                    onClick={() => onCheckedChange(selected)}
                    key={getItemLabel(selected)}
                  >
                    <CuiBadge iconSide='right' iconType='close'>
                      {getItemLabel(selected)}
                    </CuiBadge>
                  </CuiSelectedBadge>
                ))}
              </CuiFlexGroup>
            </SelectedItemsContainer>
          </CuiPad>
          <CuiSpacer size='xs' />
          <CuiInput
            value={searchValue}
            onChange={onInputChange}
            placeholder={placeholder}
            border={false}
          />
          <CuiSpacer as='hr' size='xs' />
          <CuiFilterScrollContainer>
            {items.length === 0 ? (
              <CuiPad horizontalSize='s' verticalSize='xs'>
                <span>{emptyResultsMessage}</span>
              </CuiPad>
            ) : (
              items.map((item) => renderItem(item))
            )}
          </CuiFilterScrollContainer>
          <CuiPad horizontalSize='s' bottomSize='xs'>
            <CuiFlexGroup gutterSize='s' justifyContent='spaceBetween'>
              <RadixPopover.Close asChild={true}>
                <CuiLink size='paragraph2' onClick={onClear}>
                  Clear
                </CuiLink>
              </RadixPopover.Close>
              <RadixPopover.Close asChild={true}>
                <CuiButton size='s' onClick={onSave}>
                  Save
                </CuiButton>
              </RadixPopover.Close>
            </CuiFlexGroup>
          </CuiPad>
        </CuiPad>
      </CuiFilterRoot>
    </CuiFilterSearchPopoverRoot>
  )

  function onInputChange(value: string) {
    setSearchValue(value)
    onTextChange(value)
  }

  function onClear() {
    setLocalSelected([])
    setSelectedItems([])
  }

  function onSave() {
    setSelectedItems(localSelected)
  }

  function onCheckedChange(item: TItem) {
    setLocalSelected((prevSelected) => {
      if (prevSelected.some((prevSelectedItem) => getItemKey(item) === getItemKey(prevSelectedItem))) {
        return prevSelected.filter((selected) => getItemKey(selected) !== getItemKey(item))
      }

      return [...prevSelected, item]
    })
  }

  function renderItem(item: Item<TItem>, isGroupItem?: boolean) {
    if (isItemGroup(item)) {
      return (
        <Fragment>
          <CuiPad verticalSize='xs' horizontalSize='s'>
            <CuiText>
              <b>{item.label}</b>
            </CuiText>
          </CuiPad>

          {item.items.map((item) => renderItem(item, true))}
        </Fragment>
      )
    }

    return (
      <CuiFilterItem key={getItemKey(item)} onClick={() => onCheckedChange(item)}>
        <CuiPad verticalSize='xs' horizontalSize={isGroupItem ? 'm' : 's'}>
          <CuiFlexGroup gutterSize='m' alignItems='center'>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
              <CuiCheckbox
                checked={localSelected.some(
                  (selectedItem) => getItemKey(selectedItem) === getItemKey(item)
                )}
                onChange={() => onCheckedChange(item)}
              />
            </div>
            <CuiText>{getItemLabel(item)}</CuiText>
          </CuiFlexGroup>
        </CuiPad>
      </CuiFilterItem>
    )
  }

  function getButtonLabel(
    labelTextProps?: Partial<React.ComponentProps<typeof CuiText>>,
    labelIconProps?: Partial<React.ComponentProps<typeof CuiIcon>>
  ) {
    if (selectedItems.length === 0) {
      return (
        <CuiFlexGroup gutterSize='xs' alignItems='center'>
          {labelIconProps && <CuiIcon {...labelIconProps} />}
          {labelIconProps && <CuiText> </CuiText>}
          {labelIconProps && <CuiText> </CuiText>}
          {labelTextProps ? <CuiText {...labelTextProps}>{label}</CuiText> : label}
        </CuiFlexGroup>
      )
    }

    let buttonLabel = getItemLabel(selectedItems[0])
    if (selectedItems.length > 1) {
      buttonLabel += ` +${selectedItems.length - 1}`
    }

    return (
      <CuiFlexGroup gutterSize='xs' alignItems='center' justifyContent='flexStart'>
        {labelIconProps && <CuiIcon {...labelIconProps} />}
        {labelIconProps && <CuiText> </CuiText>}
        {labelIconProps && <CuiText> </CuiText>}
        {labelTextProps ? <CuiText {...labelTextProps}>{buttonLabel}</CuiText> : buttonLabel}
      </CuiFlexGroup>
    )
  }
}

function isItemGroup<TItem>(item: Item<TItem>): item is ItemGroup<TItem> {
  // @ts-ignore
  return !!(item?.label && item?.items)
}
