import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import { CuiButton, CuiFlexGrid, CuiIcon, CuiSpacer, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import styled from 'styled-components'

import explore from '../LandingPage/images/explore.png'
import andrewli from './images/team/andrewli.png'
import angelayang from './images/team/angelayang.png'
import eliothertenstein from './images/team/eliothertenstein.jpg'
import gracepark from './images/team/gracepark.jpg'
import hannahlee from './images/team/hannahlee.png'
import jamesliu from './images/team/jamesliu.png'
import jenniferquach from './images/team/jenniferquach.png'
import karinali from './images/team/karinali.jpeg'
import susanlee from './images/team/susanlee.png'
import { Research } from './Research'

const NextChapterRoot = styled.div`
  color: ${palette.white};
  text-align: center;
  background-color: ${palette.neutral85};
  padding: 128px 348px 68px 348px;
`

const TeamRoot = styled.div`
  padding: 128px;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  gap: 70px;
  background: ${palette.white};
`

const JoinRoot = styled.div`
  background-color: ${palette.neutral0};
  text-align: center;
  padding: 128px 242px;
`

const members = [
  {
    name: 'Hannah Lee',
    role: 'Frontend Engineer',
    major: 'B.S. Computer Science',
    year: 2025,
    image: `${hannahlee}`,
    linkedin: 'https://www.linkedin.com/in/hhannahlee/',
  },
  {
    name: 'Andrew Li',
    role: 'Backend Engineer',
    major: 'B.S. Computer Science',
    year: 2025,
    image: `${andrewli}`,
  },
  {
    name: 'Karina Li',
    role: 'Product Designer',
    major: 'B.S. Computer Science',
    year: 2025,
    image: `${karinali}`,
    linkedin: 'https://www.linkedin.com/in/karina-li1023/',
  },
  {
    name: 'Susan Lee',
    role: 'Frontend Engineer',
    major: 'B.S. Computer Science',
    year: 2025,
    image: `${susanlee}`,
    linkedin: 'https://www.linkedin.com/in/susan-lee-12957a217/',
  },
  {
    name: 'Grace Park',
    role: 'Frontend Engineer',
    major: 'B.S. Computer Science',
    year: 2027,
    image: `${gracepark}`,
    linkedin: 'https://www.linkedin.com/in/grace-park04/',
  },
  {
    name: 'James Liu',
    role: 'Backend Engineer',
    major: 'B.S. Computer Science, B.S. Math',
    year: 2028,
    image: `${jamesliu}`,
    linkedin: 'https://www.linkedin.com/in/jliu28/',
  },
  {
    name: 'Eliot Hertenstein',
    role: 'Frontend Engineer',
    major: 'B.S. Computer Science, B.A. Art History',
    year: 2028,
    image: `${eliothertenstein}`,
  },
  {
    name: 'Angela Yang',
    role: 'Product Designer',
    major: 'B.S. Design & Computer Science',
    year: 2028,
    image: `${angelayang}`,
  },
  {
    name: 'Jennifer Quach',
    role: 'Product Designer',
    major: 'B.S. Symbolic Systems',
    year: 2025,
    image: `${jenniferquach}`,
    linkedin: 'https://www.linkedin.com/in/jennifer-quach-6babb119a/',
  },
]

const alumni = [
  { name: 'Alix Cui', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2023 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
  { name: 'Hannah Lee', role: 'Frontend Engineer', major: 'B.S. Computer Science', year: 2025 },
]

type MemberProps = {
  name: string
  role: string
  major: string
  year: number
  linkedin?: string
  website?: string
  image?: string
  variant: 'team' | 'alumni'
}

const MemberBox = styled.div`
  max-width: 300px;
  text-align: left;
  width: 270px;
  max-height: 384px;
  gap: 16px;
  padding-bottom: 30px;
`

const MemberImage = styled.div`
  width: 270px;
  height: 270px;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Member: FunctionComponent<PropsWithChildren<MemberProps>> = ({
  name,
  role,
  major,
  year,
  linkedin,
  website,
  image,
  variant = 'team',
}) => {
  return (
    <MemberBox>
      {variant === 'team' && (
        <MemberImage>
          <img
            src={image}
            alt={`${name}'s profile`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </MemberImage>
      )}
      <div style={{ paddingTop: '10px' }}>
        <CuiText size='title3'>
          {name}{' '}
          {variant === 'alumni' && (
            <Fragment>
              {linkedin && (
                <a href={linkedin} target='_blank' rel='noopener noreferrer'>
                  <CuiIcon type='linkedin' size='m' />
                </a>
              )}{' '}
              {website && (
                <a href={website} target='_blank' rel='noopener noreferrer'>
                  <CuiIcon type='website' size='m' />
                </a>
              )}
            </Fragment>
          )}
        </CuiText>
        <CuiText color='hushed'>{role}</CuiText>
        <CuiText color='hushed'>
          {major.split(',').map((m, index, arr) =>
            index === arr.length - 1 ? (
              <span key={index}>{m.trim()}</span>
            ) : (
              <CuiText color='hushed' key={index}>
                {m.trim()}
                <br />
              </CuiText>
            )
          )}{' '}
          {year}
        </CuiText>

        {variant === 'team' && (
          <Fragment>
            {linkedin && (
              <a href={linkedin} target='_blank' rel='noopener noreferrer'>
                <CuiIcon type='linkedin' size='m' />
              </a>
            )}{' '}
            {website && (
              <a href={website} target='_blank' rel='noopener noreferrer'>
                <CuiIcon type='website' size='m' />
              </a>
            )}
          </Fragment>
        )}
      </div>
    </MemberBox>
  )
}

const NextChapter: FunctionComponent = () => {
  return (
    <NextChapterRoot>
      <CuiText color='inherit' size='title1'>
        Building the next chapter
      </CuiText>
      <CuiSpacer size='l' />
      <CuiText color='inherit'>The future of Carta</CuiText>
      <CuiSpacer size='l' />
      <CuiText color='inherit'>
        In 2020, Carta V2 was launched as a fully student-run project independent from the CartaLab.
        We’re dedicated to serving the student body by improving the original V1 platform, and
        envisioning next-generation experiences around course planning at Stanford.
      </CuiText>
      <CuiSpacer size='l' />
      <img style={{ maxWidth: '800px' }} src={explore} alt='explore' />
    </NextChapterRoot>
  )
}

const JoinTeam: FunctionComponent = () => {
  const isClosed = true

  return (
    <JoinRoot>
      <CuiText size='title3'>JOIN OUR TEAM</CuiText>
      <CuiSpacer size='xs' />
      <CuiText size='titleDisplay'>Be a part of a mission to make education transparent.</CuiText>
      <CuiSpacer size='m' />
      {isClosed ? (
        <CuiText size='title3'>Applications are currently closed.</CuiText>
      ) : (
        <CuiButton color='accent'>
          <CuiText color='textOnDarkBackground'>Visit application</CuiText>
        </CuiButton>
      )}
    </JoinRoot>
  )
}

const MeetOurTeam: FunctionComponent = () => {
  return (
    <TeamRoot>
      <Title>
        <CuiText size='title1'>Meet Our Team</CuiText>
      </Title>
      <CuiFlexGrid columns={4} gutterSize='s'>
        {members.map((member, index) => (
          <Member key={index} {...member} variant='team' />
        ))}
      </CuiFlexGrid>
      <CuiText size='title1'>Alumni</CuiText>
      <CuiFlexGrid columns={4} gutterSize='xl'>
        {alumni.map((alum, index) => (
          <Member key={index} {...alum} variant='alumni' />
        ))}
      </CuiFlexGrid>
    </TeamRoot>
  )
}

export const Team: FunctionComponent = () => {
  return (
    <Fragment>
      <Research />
      <NextChapter />
      <MeetOurTeam />
      <JoinTeam />
    </Fragment>
  )
}
