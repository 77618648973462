import { type FunctionComponent } from 'react'
import { type APISchemas } from 'src/api/api'
import { CuiButton, CuiDropdown, CuiFlexGroup, CuiPad, CuiText } from 'src/cui/components'
import styled from 'styled-components'

import FAKE_DATA from './data.json'

type CoursePlanWrapperProps = {
  $isChecked?: boolean
}
const MajorPlanWrapper = styled.div<CoursePlanWrapperProps>`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.cuiColors.background};
  border-radius: 4px;
  padding: 20px;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  :hover {
    background: ${({ theme }) => theme.cuiColors.background};
  }
`

const CoursePlanContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 8px;
`

const CourseTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  jusitfy-self: center;
  height: 100%;
`
const UnitsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

type Props = {
  /**
   * The id of the plan to display
   *
   */
  planId: string
  /**
   *
   * The color of the bar on the left
   *
   */
  color?: string
  /**
   *
   * Function passed in to handle course being marked as taken or marked as not taken
   *
   */
  onCheck?: ({ checked, course }: { checked: boolean; course: APISchemas['MetaOffering'] }) => void
}

const gradeOptions = [
  'A+',
  'A',
  'A-',
  'B+',
  'B',
  'B-',
  'C+',
  'C',
  'C-',
  'D+',
  'D',
  'D-',
  'F',
  'CR',
  'NC',
]
const loggedInUser = (
  <CuiButton iconSide='right' iconType='caret-down'>
    <CuiText size='caption'>Select</CuiText>
  </CuiButton>
)

const MajorPlanHeadings = ['Requirement', 'Fulfilled By', 'Units', 'Grade']

export const MajorPlan: FunctionComponent<Props> = ({ planId }) => {
  return (
    <MajorPlanWrapper>
      {MajorPlanHeadings.map((heading, index) => (
        <CuiFlexGroup direction='column' gutterSizeHorizontal='xs' key={index}>
          <CuiText size='title2'>{heading}</CuiText>
          <CuiPad verticalSize='s' />
          {FAKE_DATA[0].courses.map((course) => (
            <CoursePlanContentWrapper key={course.id}>
              {index === 0 && (
                <CourseTitleWrapper>
                  <CuiText
                    size='paragraph1'
                    clamp={1}
                  >{`${course.meta_offering.courseCode.subject} ${course.meta_offering.courseCode.catalogNumber}`}</CuiText>
                  <CuiPad verticalSize='s' />
                </CourseTitleWrapper>
              )}
              {index === 1 && (
                <CuiText size='paragraph1' clamp={1}>
                  {planId}
                </CuiText>
              )}
              {index === 2 && (
                <UnitsWrapper>
                  <CuiText size='paragraph1' clamp={1}>
                    1 unit{/* {getNumUnits(course.meta_offering)} */}
                  </CuiText>
                  <CuiPad verticalSize='s' />
                </UnitsWrapper>
              )}
              {index === 3 && (
                <ButtonsWrapper>
                  <CuiDropdown
                    options={gradeOptions}
                    trigger={loggedInUser}
                    // eslint-disable-next-line no-alert
                    getOptionSelect={(option) => alert(option)}
                    getOptionKey={(option) => option}
                    getOptionLabel={(option) => option}
                  />
                </ButtonsWrapper>
              )}
            </CoursePlanContentWrapper>
          ))}
        </CuiFlexGroup>
      ))}
    </MajorPlanWrapper>
  )

  // function getNumUnits(course: APISchemas['MetaOffering']) {
  //   let numUnits
  //   if (course.units.minimum === course.units.maximum) {
  //     if (course.units.minimum === 1) {
  //       numUnits = `${course.units.minimum.toString()} unit`
  //     } else {
  //       numUnits = `${course.units.minimum.toString()} units`
  //     }
  //   } else {
  //     numUnits = `${course.units.minimum.toString()}-${course.units.maximum.toString()} units`
  //   }

  //   return numUnits
  // }
}
