import { Link, Outlet, useMatchRoute, useSearch } from '@tanstack/react-location'
import {
  Fragment,
  type FunctionComponent,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { type APISchemas } from 'src/api/api'
import { stringifyCourseCode } from 'src/api/course'
import { useDeleteMainPlan, useEditMainPlan } from 'src/api/plan'
import { useTerm } from 'src/api/term'
import { useAppDispatch } from 'src/AppRoot/hooks'
import { addCourseToCalendar, removeCourseFromCalendar, replaceOptimisticId } from 'src/AppRoot/store'
import {
  CuiButton,
  CuiFilterSearch,
  CuiFlexGroup,
  CuiIcon,
  CuiPad,
  CuiSpacer,
  CuiTabs,
  CuiText,
} from 'src/cui/components'

import { CourseData } from './CourseData'
import { CourseOverview } from './CourseOverview'
import { CourseReviews } from './CourseReviews'
import { CourseSchedule } from './CourseSchedule'

type Props = { course: APISchemas['MetaOffering'] }

type CourseDetailTab = 'overview' | 'schedule' | 'data' | 'reviews'

const CourseTabs = {
  overview: CourseOverview,
  schedule: CourseSchedule,
  data: CourseData,
  reviews: CourseReviews,
}

export const CourseDetail: FunctionComponent<PropsWithChildren<Props>> = ({ course }) => {
  const dispatch = useAppDispatch()
  const matchRoute = useMatchRoute()
  const searchParams = useSearch()

  const { courseCode, title } = course
  const globalQuarter = useSelector((state: any) => state.calendar.globalQuarter)
  const globalYear = useSelector((state: any) => state.calendar.globalYear)

  const { mutateAsync: getTerm } = useTerm()

  const [termId, setTermId] = useState('')

  useEffect(() => {
    const fetchTermId = async () => {
      try {
        const termIds = (await getTerm({ params: { endYear: globalYear } })) as
          | { [key: string]: { id: string } }
          | undefined
        if (termIds && globalQuarter >= 0 && globalQuarter < Object.keys(termIds).length) {
          const { id } = termIds[Object.keys(termIds)[globalQuarter]]
          setTermId(id)
        }
      } catch (error) {
        console.error('Error fetching term ID:', error)
      }
    }

    fetchTermId()
  }, [globalYear, getTerm, globalQuarter])

  const activeTab = getActiveTab()
  const tabs = getTabs()
  const ActiveTabComponent = CourseTabs[activeTab]
  const sunetId = useSelector((state: any) => state.user.sunetId)

  const { mutate: addToCalendar } = useEditMainPlan()
  const handleAddToCalendar = () => {
    const formattedCourse: APISchemas['SavedCourse'] = {
      id: course.id,
      metaOffering: course,
      termAddedId: termId,
    }

    addToCalendar(
      {
        params: { sunetId: sunetId ?? 'hhannah' },
        body: {
          metaOfferingId: course.id,
          termAddedId: termId,
        },
      },
      {
        onSuccess: (data) => {
          dispatch(addCourseToCalendar(formattedCourse))
          dispatch(replaceOptimisticId({ oid: course.id, id: data.id }))
        },
      }
    )
  }

  const { mutate: deleteCourse } = useDeleteMainPlan()

  const handleDelete = (sunetId: string, course: APISchemas['SavedCourse']) => {
    deleteCourse(
      { sunetId, savedcourseid: course.id },
      {
        onSuccess: () => {
          dispatch(removeCourseFromCalendar(course))
        },
      }
    )
  }

  const { calendarData } = useSelector((state: any) => state.calendar)
  const inCalendar = useMemo(() => {
    return calendarData.some(
      (savedCourse: APISchemas['SavedCourse']) => savedCourse.metaOffering.id === course.id
    )
  }, [calendarData, course.id])
  const matchingCourse = useMemo(() => {
    return calendarData.find(
      (savedCourse: APISchemas['SavedCourse']) => savedCourse.metaOffering.id === course.id
    )
  }, [calendarData, course.id])

  type ItemType = { text: string }
  const [selectedItems, setSelectedItems] = useState<ItemType[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const allItems: ItemType[] = [
    { text: 'Dreading' },
    { text: 'Dream Classes' },
    { text: 'Unit Fillers' },
    { text: 'Newest List' },
    { text: 'WAYs' },
    { text: 'MUST TAKE' },
    { text: 'List 1' },
    { text: 'List 2' },
    { text: 'List 3' },
    { text: 'List 4' },
    { text: 'List 5' },
    { text: 'List 6' },
    { text: 'List 7' },
    { text: 'List 8' },
    { text: 'List 9' },
    { text: 'List 10' },
  ]
  const filteredItems = allItems.filter((item) =>
    item.text.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <Fragment>
      <CuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
        <CuiText size='title1'>{stringifyCourseCode(courseCode)}</CuiText>
        <CuiFlexGroup alignItems='center' gutterSize='s'>
          <CuiButton
            onClick={() =>
              inCalendar ? handleDelete(sunetId ?? 'hhannah', matchingCourse) : handleAddToCalendar()
            }
            color={inCalendar ? 'accent' : 'accent'}
          >
            <CuiIcon type='calendar' color='inverse' />
            <CuiPad horizontalSize='xs' />
            <CuiText color='textOnDarkBackground' size='title4'>
              {inCalendar ? 'Planned' : 'Plan'}
            </CuiText>
          </CuiButton>
          <CuiFilterSearch
            /*
             * TODO for Future Usage:
             * Change getItemKey and getItemLabel to item.key or item.id for uniqueness
             */
            getItemKey={(item: ItemType) => item.text}
            getItemLabel={(item: ItemType) => item.text}
            items={filteredItems}
            label='Saved List'
            onTextChange={(value) => setSearchQuery(value)}
            placeholder='Search'
            selectedItems={selectedItems}
            setSelectedItems={(newSelectedItems) => {
              setSelectedItems(newSelectedItems)
            }}
            labelTextProps={{
              color: 'text',
              size: 'title4',
            }}
            labelIconProps={{
              type: 'heart',
              color: 'text',
            }}
          />
        </CuiFlexGroup>
      </CuiFlexGroup>
      <CuiSpacer size='s' />
      <CuiText size='title2'>{title}</CuiText>
      <CuiSpacer size='s' />
      <CuiTabs items={tabs} />
      <CuiSpacer size='none' as='hr' />
      <CuiSpacer size='s' />
      <Outlet />
      <ActiveTabComponent course={course} />
    </Fragment>
  )

  function getTabs() {
    return [
      {
        label: 'Overview',
        active: activeTab === 'overview',
        as: Link,
        asProps: {
          to: 'overview',
          search: searchParams,
        },
      },
      {
        label: 'Schedule',
        active: activeTab === 'schedule',
        as: Link,
        asProps: {
          to: 'schedule',
          search: searchParams,
        },
      },
      {
        label: 'Data',
        active: activeTab === 'data',
        as: Link,
        asProps: {
          to: 'data',
          search: searchParams,
        },
      },
      {
        label: 'Reviews',
        active: activeTab === 'reviews',
        as: Link,
        asProps: {
          to: 'reviews',
          search: searchParams,
        },
      },
    ]
  }

  function getActiveTab(): CourseDetailTab {
    if (matchRoute({ to: 'schedule' })) {
      return 'schedule'
    }

    if (matchRoute({ to: 'data' })) {
      return 'data'
    }

    if (matchRoute({ to: 'reviews' })) {
      return 'reviews'
    }

    return 'overview'
  }
}
