import {
  type DefaultGenerics,
  Navigate,
  type Route,
  type SyncOrAsyncElement,
} from '@tanstack/react-location'
import { type FunctionComponent, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useCreateMainPlan, useMainPlan } from 'src/api/plan'
import { CuiFlexGroup, CuiLoadingSpinner } from 'src/cui/components'
import { CuiThemeProvider } from 'src/cui/CuiThemeProvider'

import { AboutPage } from './About'
import { ApplyPage } from './Apply'
import { DashboardContainer } from './DashboardContainer'
import { Home } from './Home'
import { Sidebar } from './Home/Sidebar'
import { LandingPage } from './LandingPage'
import { MajorPlanner } from './MajorPlanner'
import { MajorPlannerSidebar } from './MajorPlanner/MajorPlannerSidebar'
import { Planner } from './Planner'
import { SavedCourses } from './Saved'

const RootRouteWrapper: FunctionComponent = () => {
  const sunetId = useSelector((state: any) => state.user.sunetId)
  const { data: mainPlan, refetch, isLoading: isMainPlanLoading } = useMainPlan({ sunetId })
  const { mutate: createMainPlan } = useCreateMainPlan()
  const hasTriedCreating = useRef(false)

  useEffect(() => {
    if (sunetId && !mainPlan && !isMainPlanLoading && !hasTriedCreating.current) {
      hasTriedCreating.current = true
      createMainPlan(
        { sunetId },
        {
          onSuccess: () => {
            refetch()
          },
          onError: () => {
            hasTriedCreating.current = false
          },
        }
      )
    }
  }, [sunetId, mainPlan, isMainPlanLoading, createMainPlan, refetch])

  if (!mainPlan) {
    return (
      <CuiFlexGroup alignItems='center' justifyContent='center' height='100vh'>
        <CuiLoadingSpinner />
      </CuiFlexGroup>
    )
  }

  return (
    <CuiFlexGroup>
      <Sidebar />
      <DashboardContainer>
        <Home.Home />
      </DashboardContainer>
    </CuiFlexGroup>
  )
}

const staticRoutes: Route<DefaultGenerics>[] = [
  {
    path: '/landing',
    element: (
      // force light theme for static pages
      <CuiThemeProvider theme='cui-light'>
        <LandingPage />
      </CuiThemeProvider>
    ),
  },
  {
    path: '/about',
    element: (
      // force light theme for static pages
      <CuiThemeProvider theme='cui-light'>
        <AboutPage />
      </CuiThemeProvider>
    ),
  },
  {
    path: '/apply',
    element: (
      <CuiThemeProvider theme='cui-light'>
        <ApplyPage />
      </CuiThemeProvider>
    ),
  },
]
const authenticatedRoutes: Route<DefaultGenerics>[] = [
  {
    path: '/saved',
    element: (
      <CuiFlexGroup>
        <Sidebar />
        <DashboardContainer>
          <SavedCourses />
        </DashboardContainer>
      </CuiFlexGroup>
    ),
  },
  {
    path: '/planner',
    element: (
      <CuiFlexGroup>
        <Sidebar />
        <DashboardContainer>
          <Planner.Planner />
        </DashboardContainer>
      </CuiFlexGroup>
    ),
  },
  {
    path: '/major-planner',
    element: (
      <CuiFlexGroup>
        <MajorPlannerSidebar />
        <DashboardContainer>
          <MajorPlanner.MajorPlanner />
        </DashboardContainer>
      </CuiFlexGroup>
    ),
  },
  {
    path: '/',
    element: <RootRouteWrapper />,
    children: [
      {
        path: ':courseId',
        element: <Home.CourseDetail />,
        // Tab routing is handled within CourseDetail
        children: [
          {
            path: '/overview',
          },
          {
            path: '/schedule',
          },
          {
            path: '/data',
          },
          {
            path: '/reviews',
          },
        ],
      },
    ],
  },
]

const AuthenticatedRoute: FunctionComponent<{
  element: SyncOrAsyncElement<DefaultGenerics>
}> = ({ element }) => {
  const sunetId = useSelector((state: any) => state.user.sunetId)
  const isAuthenticated = !!sunetId
  if (isAuthenticated) {
    return element as React.ReactElement
  }

  return <Navigate to='/landing' />
}

function wrapAuthenticatedRoute(route: Route<DefaultGenerics>): Route<DefaultGenerics> {
  return {
    ...route,
    loader: route.loader,
    element: <AuthenticatedRoute element={route.element} />,
    children: route.children ? wrapAuthenticatedRoutes(route.children) : undefined,
  }
}

function wrapAuthenticatedRoutes(routes: Route<DefaultGenerics>[]): Route<DefaultGenerics>[] {
  return routes.map(wrapAuthenticatedRoute)
}

export const routes = [...staticRoutes, ...wrapAuthenticatedRoutes(authenticatedRoutes)]
