import { useNavigate } from '@tanstack/react-location'
import { type FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { type APISchemas } from 'src/api/api'
import { requirementAbbreviation } from 'src/api/course'
import { useDeleteMainPlan } from 'src/api/plan'
import { useAppDispatch } from 'src/AppRoot/hooks'
import { removeCourseFromCalendar } from 'src/AppRoot/store'
import { STATIC_SIDEBAR_WIDTH } from 'src/routes/Home/Sidebar'
import { getCourseTitle, getCourseUnits } from 'src/utils/calendarFunctions'
import styled from 'styled-components'

import { CuiBadge } from '../CuiBadge'
import { CuiButton } from '../CuiButton'
import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiSpacer } from '../CuiSpacer'
import { CuiText } from '../CuiText'

type PinnedProps = { isMajor: boolean }

const CourseRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${STATIC_SIDEBAR_WIDTH};
  padding: 4px 26px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.cuiColors.background};
  cursor: pointer;
`

const SmallButtonWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Course = styled.div<{ isMajor: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  border-radius: 4px;
  border: ${({ theme, isMajor }) =>
    isMajor
      ? `0.62px solid ${theme.cuiColors.darkShade}`
      : `0.5px solid ${theme.cuiColors.lightestShade}`};
  border-left: 5px solid
    ${({ theme, isMajor }) => (isMajor ? theme.cuiColors.darkAccent : theme.cuiColors.darkestShade)};
  box-shadow: ${({ theme, isMajor }) =>
    isMajor ? 'none' : `0px 0px 16px ${theme.cuiColors.boxShadow}`};
`

export const CuiPinnedCourses: FunctionComponent<PinnedProps> = ({ isMajor }) => {
  const { mutate: deleteCourse } = useDeleteMainPlan()
  const data = useSelector((state: any) => state.calendar.calendarData)

  const dispatch = useAppDispatch()

  const handleDelete = (sunetId: string, course: APISchemas['SavedCourse']) => {
    deleteCourse(
      { sunetId, savedcourseid: course.id },
      {
        onSuccess: () => {
          dispatch(removeCourseFromCalendar(course))
        },
      }
    )
  }

  const navigate = useNavigate()
  const sunetId = useSelector((state: any) => state.user.sunetId)

  return (
    <CuiFlexGroup direction='column' alignItems='center' gutterSizeVertical='xs'>
      <CuiText size='title3'>&nbsp; &nbsp; {isMajor ? 'Spring 2023' : 'Courses'}</CuiText>
      {(!data || data.length === 0) && <div style={{ width: STATIC_SIDEBAR_WIDTH }} />}
      {data?.map((course: APISchemas['SavedCourse']) => (
        <CourseRoot
          key={course.metaOffering?.id}
          onClick={() => navigate({ to: `/${course.metaOffering?.id}` })}
        >
          <CuiFlexGroup direction='row' gutterSizeHorizontal='s'>
            <Course isMajor={isMajor}>
              <CuiFlexGroup gutterSizeHorizontal='xl' justifyContent='flexStart'>
                <CuiFlexGroup direction='row' gutterSizeHorizontal='s'>
                  <CuiText size='label'>{getCourseTitle(course.metaOffering?.courseCode)}</CuiText>
                  <CuiText size='caption'>
                    {getCourseUnits(
                      course.metaOffering?.units.minimum,
                      course.metaOffering?.units.maximum
                    )}
                  </CuiText>
                  <SmallButtonWrapper>
                    <CuiButton
                      iconType='calendar'
                      size='s'
                      onClick={() => handleDelete(sunetId ?? 'hhannah', course)}
                    />
                  </SmallButtonWrapper>
                  <SmallButtonWrapper>
                    <CuiButton
                      iconType={!isMajor ? 'close' : 'check'}
                      size='s'
                      onClick={() => handleDelete(sunetId ?? 'hhannah', course)}
                    />
                  </SmallButtonWrapper>
                </CuiFlexGroup>
              </CuiFlexGroup>
            </Course>
            {course.metaOffering?.requirements?.value?.length ? (
              <CuiFlexGroup gutterSizeHorizontal='xs'>
                {course.metaOffering.requirements.value.map((req) => (
                  <CuiBadge key={req}>
                    <CuiText size='label'>{requirementAbbreviation[req].toUpperCase()}</CuiText>
                  </CuiBadge>
                ))}
              </CuiFlexGroup>
            ) : null}
          </CuiFlexGroup>
        </CourseRoot>
      ))}
      <CuiSpacer size='s' />
    </CuiFlexGroup>
  )
}
