import { type InfiniteData, useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { api, type APIRoutes, type APISchemas } from './api'
type GetCourseParams = { id: string; year: number }

function getCourse({ id, year }: GetCourseParams): Promise<APISchemas['MetaOffering']> {
  return api.get(`Course/${year}/${id}`).json<APISchemas['MetaOffering']>()
}

function getCourses(
  params: APIRoutes['/Course/{year}']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}']['get']['parameters']['query']
): Promise<APISchemas['MetaOfferingPageResults']> {
  return api.get(`Course/${params.year}`, { searchParams }).json<APISchemas['MetaOfferingPageResults']>()
}

export function useCourse(params: GetCourseParams) {
  const queryClient = useQueryClient()

  return useQuery(['Course/{year}/{id}', 'get', params], () => getCourse(params), {
    staleTime: Infinity,
    initialData: () => {
      const queryData = queryClient.getQueryData<InfiniteData<APISchemas['MetaOfferingPageResults']>>(
        ['/Course/{year}', 'get', { year: params.year }],
        { exact: false }
      )
      if (!queryData) {
        return
      }

      return queryData.pages.flatMap((page) => page.entries).find((course) => course.id === params.id)
    },
  })
}

export function useInfiniteCourses(
  params: APIRoutes['/Course/{year}']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}']['get']['parameters']['query'] = {},
  options: { enabled?: boolean } = { enabled: true }
) {
  const { data: rawPages, ...rest } = useInfiniteQuery(
    ['/Course/{year}', 'get', params, searchParams],
    ({ pageParam = 1 }) => getCourses(params, { pageNumber: pageParam, ...searchParams }),
    {
      enabled: options.enabled,
      staleTime: Infinity,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      getPreviousPageParam: (firstPage) => firstPage.previousPage,
    }
  )

  const data = useMemo(
    () =>
      rawPages
        ? {
            totalEntryCount: rawPages.pages[0].totalEntryCount,
            courses: rawPages.pages.flatMap((page) => page.entries),
          }
        : undefined,
    [rawPages]
  )

  return { data, ...rest }
}

export function useInfiniteSearchCourses(
  params: APIRoutes['/Course/{year}/Search']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}/Search']['get']['parameters']['query'] = {},
  options: { enabled?: boolean } = { enabled: true }
) {
  const { data: rawPages, ...rest } = useInfiniteQuery(
    ['/Course/{year}/Search', 'get', params, searchParams, 'infinite'],
    ({ pageParam = 1 }) => searchCourses(params, { pageNumber: pageParam, ...searchParams }),
    {
      enabled: options.enabled,
      staleTime: Infinity,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      getPreviousPageParam: (firstPage) => firstPage.previousPage,
    }
  )

  const data = useMemo(
    () =>
      rawPages
        ? {
            totalEntryCount: rawPages.pages[0].totalEntryCount,
            courses: rawPages.pages.flatMap((page) => page.entries),
          }
        : undefined,
    [rawPages]
  )

  return { data, ...rest }
}

function searchCourses(
  params: APIRoutes['/Course/{year}/Search']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}/Search']['get']['parameters']['query']
): Promise<APISchemas['MetaOfferingPageResults']> {
  return api
    .get(`Course/${params.year}/Search`, {
      //@ts-ignore
      searchParams,
    })
    .json<APISchemas['MetaOfferingPageResults']>()
}

export function useSearch(
  params: APIRoutes['/Course/{year}/Search']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}/Search']['get']['parameters']['query'],
  options: { enabled?: boolean } = { enabled: true }
) {
  return useQuery(
    ['/Course/{year}/Search', 'get', params, searchParams],
    () => searchCourses(params, searchParams),
    {
      enabled: options.enabled,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )
}

export function getCourseSchedules(
  params: APIRoutes['/Course/{id}/Schedules']['get']['parameters']['path']
): Promise<APISchemas['SchedulesPageResults'][]> {
  return api.get(`Course/${params.id}/Schedules`).json<APISchemas['SchedulesPageResults'][]>()
}

export function useCourseSchedules(
  params: APIRoutes['/Course/{id}/Schedules']['get']['parameters']['path'],
  options: { enabled?: boolean } = { enabled: true }
) {
  return useQuery(['/Course/{id}/Schedules', 'get', params], () => getCourseSchedules(params), {
    enabled: options.enabled,
    staleTime: Infinity,
  })
}

export function stringifyCourseCode(courseCode: APISchemas['CourseCode']): string {
  return `${courseCode.subject} ${courseCode.catalogNumber}`
}

export function stringifyUnits(units: APISchemas['MetaOffering']['units']): string {
  const { minimum, maximum } = units!
  if (minimum === maximum) {
    return String(minimum)
  }

  return `${minimum} - ${maximum}`
}

export const seasonAbbreviation: Record<APISchemas['TermSeason'], string> = {
  Autumn: 'Aut',
  Winter: 'Win',
  Spring: 'Spr',
  Summer: 'Sum',
}
export const requirementAbbreviation: Record<APISchemas['UndergradRequirement'], string> = {
  WayAII: 'Way — AII',
  WayAQR: 'Way — AQR',
  WayCE: 'Way — CE',
  WayED: 'Way — EDP',
  WayER: 'Way — ER',
  WayFR: 'Way — FR',
  WaySI: 'Way — SI',
  WaySMA: 'Way — SMA',
  Language: 'Language',
  Write1: 'PWR 1',
  Write2: 'PWR 2',
  WriteSLE: 'Write SLE',
  College: 'College',
}
export const friendlyGradingBasisText: Record<APISchemas['GradingBasis'], string> = {
  Letter: 'Letter',
  LetterOrCRNC: 'Letter, C/NC',
  SatisfactoryNC: 'Satisfactory/NC',
  MedicalOption: 'Medical Option',
  MedicalPlusMinus: 'Medical Plus/Minus',
  MedicalSatisfactoryNC: 'MedicalSatisfactory/NC',
  GSBLetter: 'GSB Letter',
  GSBPassFail: 'GSB Pass/Fail',
  GSBStudentOption: 'GSB Student Option',
  Law: 'Law',
  LawPassFail: 'Law Pass/Fail',
  LawMixed: 'Law Mixed',
  TGR: 'TGR',
}
export const friendlyAttendanceText: Record<APISchemas['InstructionMode'], string> = {
  IndependentStudies: 'Independent Study',
  InPerson: 'In Person',
  OnlineAsynchronous: 'Online · Asynchronous',
  OnlineSynchronous: 'Online · Synchronous',
  RemoteAsynchronous: 'Remote · Asynchronous',
  RemoteSynchronous: 'Remote · Synchronous',
  Television: 'Television',
  Videotape: 'Videotape',
  WorldWideWeb: 'WorldWide Web',
}
