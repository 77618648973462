import { type APISchemas } from 'src/api/api'

import { ticksToHours } from './ticksToHours'

export const quarters = ['Autumn', 'Winter', 'Spring', 'Summer']

export type CuiCalendarDataProps = {
  /**
   *
   * information about schedule
   *
   *
   */
  scheduleData?: APISchemas['Schedules'][]

  /**
   *
   * course id
   *
   *
   */
  courseId?: string

  /**
   *
   * quarter to display
   *
   * @default 'Autumn'
   */
  currentQuarter: string

  /**
   *
   * year to display
   *
   * @default 2025
   */
  endYear: number
}

const DAY_TO_INDEX: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
}

const CALENDAR_START_HOUR = 8

export function getCourseTitle(courseCode: { subject: string; catalogNumber: string }) {
  if (!courseCode) {
    return ''
  }

  if (courseCode.subject.length > 4) {
    return `${courseCode.subject.slice(-3)}...${courseCode.catalogNumber}`
  }

  const courseTitle = `${courseCode.subject} ${courseCode.catalogNumber}`

  return courseTitle
}

export function getCourseUnits(minimum: number, maximum: number) {
  let units = `${minimum}`

  if (minimum !== maximum) {
    units = `${minimum}-${maximum}`
  }

  return `${units}`
}

export function getCourseStart(props: CuiCalendarDataProps) {
  const { scheduleData, currentQuarter, endYear } = props

  const filteredData = scheduleData?.filter(
    (entry) =>
      entry.term.season === currentQuarter &&
      entry.term.endYear === endYear &&
      (entry.section.component === 'Lecture' || entry.section.component === 'Laboratory')
  )

  if (!filteredData || filteredData.length === 0) {
    return 0
  }

  const time = ticksToHours(filteredData[0].meetingTime.lowerBound.tickOfDay)

  return time - CALENDAR_START_HOUR
}

export function getCourseDuration(props: CuiCalendarDataProps) {
  const { scheduleData, currentQuarter, endYear } = props
  const filteredData = scheduleData?.filter(
    (entry) =>
      entry.term.season === currentQuarter &&
      entry.term.endYear === endYear &&
      (entry.section.component === 'Lecture' || entry.section.component === 'Laboratory')
  )
  if (!filteredData || filteredData.length === 0) {
    return -1
  }

  const duration =
    ticksToHours(filteredData[0].meetingTime.upperBound.tickOfDay) -
    ticksToHours(filteredData[0].meetingTime.lowerBound.tickOfDay)

  return duration
}

export function getCourseDay(props: CuiCalendarDataProps): number[] {
  const { scheduleData, currentQuarter, endYear } = props

  const filteredData = scheduleData?.filter(
    (entry) =>
      entry.term.season === currentQuarter &&
      entry.term.endYear === endYear &&
      (entry.section.component === 'Lecture' || entry.section.component === 'Laboratory')
  )

  if (!filteredData || filteredData.length === 0) {
    return [-1]
  }

  return filteredData[0].meetingDays.map((day) => DAY_TO_INDEX[day])
}
