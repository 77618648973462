import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import { CuiFlexGroup, CuiSpacer, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import styled from 'styled-components'

import compare from './images/compare.png'
import explore from './images/explore rounded.png'
import roadmap from './images/roadmap.png'
import tools from './images/tools.png'

type FeatureProps = {
  title: string
  description: string
  reverse?: boolean // to reverse order of the columns as necessary
  subtitle?: string
  image: string
}

const FeatureRoot = styled.div`
  padding-top: 128px;
  padding-left: 144px;
  padding-right: 144px;

  &:last-child {
    padding-bottom: 128px;
  }
`

const ContentColumn = styled.div`
  &:first-child {
    max-width: 620px;
  }
  max-width: 550px;
`

const ImageColumn = styled.div`
  img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
  max-width: 600px;
`

const Feature: FunctionComponent<PropsWithChildren<FeatureProps>> = ({
  subtitle,
  title,
  description,
  reverse = false,
  image,
}) => {
  return (
    <FeatureRoot>
      <CuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='center'>
        {reverse ? (
          <Fragment>
            <ImageColumn>
              <img src={image} alt={title} />
            </ImageColumn>
            <ContentColumn>
              <CuiFlexGroup direction='column' alignItems='flexStart'>
                {subtitle && (
                  <div>
                    <CuiText size='paragraph1'>{subtitle}</CuiText>
                    <CuiSpacer size='l' />
                  </div>
                )}
                <CuiText size='title1'>{title}</CuiText>
                <CuiSpacer size='l' />
                <CuiText size='paragraph1'>{description}</CuiText>
              </CuiFlexGroup>
            </ContentColumn>
          </Fragment>
        ) : (
          <Fragment>
            <ContentColumn>
              <CuiFlexGroup direction='column' alignItems='flexStart'>
                {subtitle && (
                  <div>
                    <CuiText size='paragraph1'>{subtitle}</CuiText>
                    <CuiSpacer size='l' />
                  </div>
                )}
                <CuiText size='title1'>{title}</CuiText>
                <CuiSpacer size='l' />
                <CuiText size='paragraph1'>{description}</CuiText>
              </CuiFlexGroup>
            </ContentColumn>
            <ImageColumn>
              <img src={image} alt={title} />
            </ImageColumn>
          </Fragment>
        )}
      </CuiFlexGroup>
    </FeatureRoot>
  )
}

const FactRoot = styled.div`
  color: ${palette.white};
  max-width: 270px;
  min-width: 270px;
  padding: 16px 0;
  text-align: center;
`

const FooterRoot = styled.div`
  color: ${palette.white};
  max-width: 576px;
  min-width: 270px;
  text-align: center;
`
type FactProps = { stat: string; description: string }

const Fact: FunctionComponent<PropsWithChildren<FactProps>> = ({ stat, description }) => {
  return (
    <div>
      <FactRoot>
        <CuiText as='div' size='titleDisplay' color='inherit'>
          {stat}
        </CuiText>

        <CuiText size='paragraph1' color='inherit'>
          {description}
        </CuiText>
      </FactRoot>
    </div>
  )
}

const LoveRoot = styled.div`
  background: linear-gradient(66deg, ${palette.brandMain70} -35.27%, #b71c1c 87.78%);
  padding: 128px 144px;
  color: white;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const LoveBubble = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  color: black;
`

const Love: FunctionComponent = () => {
  return (
    <LoveRoot>
      <div style={{ maxWidth: '564px', textAlign: 'center', marginBottom: '72px' }}>
        <CuiText size='titleDisplay' color='inherit'>
          Students love what we're doing.
        </CuiText>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
        <div style={{ flex: 1.6 }}>
          <LoveBubble>
            <CuiText size='paragraph1' color='inherit'>
              “My sister showed me Carta. It broke down the amount of work that [each class] would be,
              and then I was like, ‘okay this is manageable.{' '}
              <CuiText as='span' size='title3'>
                I think I can plan classes on my own
              </CuiText>
              .’”
            </CuiText>
          </LoveBubble>
        </div>
        <div style={{ flex: 1.1 }}>
          <LoveBubble>
            <CuiText size='paragraph1' color='inherit'>
              “[Carta] is a very{' '}
              <CuiText as='span' size='title3'>
                vital part to my life decisions
              </CuiText>
              . Every time I don’t plan out a quarter fully [with Carta] I regret it after.”
            </CuiText>
          </LoveBubble>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
        <div style={{ flex: 1 }}>
          <LoveBubble>
            <CuiText size='paragraph1' color='inherit'>
              “I’m actually using Carta to narrow in on the courses that I think are going to be{' '}
              <CuiText as='span' size='title3'>
                most valuable
              </CuiText>
              .”
            </CuiText>
          </LoveBubble>
        </div>
        <div style={{ flex: 1.4 }}>
          <LoveBubble>
            <CuiText size='paragraph1' color='inherit'>
              “Explore Courses tells you what the class is about, but I feel like Carta gives you a more
              accurate reflection of what the{' '}
              <CuiText as='span' size='title3'>
                students are saying
              </CuiText>
              .”
            </CuiText>
          </LoveBubble>
        </div>
      </div>
    </LoveRoot>
  )
}

const FactsRoot = styled.div`
  background-color: ${palette.neutral90};
  padding: 96px 144px;
`

export const Facts: FunctionComponent<PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <FactsRoot>
        <CuiFlexGroup direction='row' justifyContent='spaceEvenly' wrap={true}>
          <Fact stat='2016' description='V1 launched as a research project by the CartaLab.' />
          <Fact stat='5' description='research papers by the CartaLab enabled by this platform.' />
          <Fact stat='30+' description='undergraduate engineers and designers over the past 4 years.' />
          <Fact stat='95%' description='of undergraduate students use Carta regularly.' />
        </CuiFlexGroup>
      </FactsRoot>
      <div>
        <Feature
          title='Tools for maximizing knowledge and realizing potential.'
          description='Students have a limited amount of time to take advantage of the classroom opportunities presented at Stanford. We build tools and provide information to help them feel content with their curricular decisions.'
          image={tools}
        />
      </div>
      <Love />
      <div>
        <Feature
          subtitle='Explore'
          title='Find a class that fits your needs.'
          description='Carta augments search with course recommendations and a departmental catalog to help you build out your best class schedule — whether you want to find specific requirements or try new things.'
          reverse={true}
          image={explore}
        />
        <Feature
          subtitle='Compare'
          title='Make the final choice with confidence.'
          description='We display Stanford-provided data such as enrollment statistics, time commitment estimates, reviews, and more to make finalizing your quarter more certain.'
          image={compare}
        />
        <Feature
          subtitle='Plan (coming soon)'
          title='Build a roadmap for your education that evolves with you.'
          description='We’re building a course planning tool tailored to the undergradute experience because as students grow, their tools should grow with them.'
          reverse={true}
          image={roadmap}
        />
      </div>

      <FactsRoot>
        <CuiFlexGroup direction='column' alignItems='center' justifyContent='center'>
          <FooterRoot>
            <CuiText color='inherit'>Work with us</CuiText>
            <CuiSpacer size='l' />
            <CuiText size='title1' color='inherit'>
              We’ve got big plans on the way.
            </CuiText>
            <CuiSpacer size='l' />
            {/* The following needs links to be updated later */}
            <CuiText color='inherit'>
              Interested in changing the way students craft their educations? Curious what we’re up to as
              a student-run team? Shoot us an email, chat with our designers, or apply to join as a
              student designer or engineer!
            </CuiText>
          </FooterRoot>
        </CuiFlexGroup>
      </FactsRoot>
    </div>
  )
}
