import { type FunctionComponent, type PropsWithChildren } from 'react'
import { CuiFlexGroup, CuiSpacer, CuiText } from 'src/cui/components'
import styled from 'styled-components'

import { Facts } from './Facts'
import explore from './images/explore.png'
import illustration from './images/illustration.svg'
import { StaticNavbar } from './StaticNavbar'

const BackgroundWrapper = styled.div`
  background-image: url(${illustration});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`

const Hero: FunctionComponent = () => {
  return (
    <BackgroundWrapper>
      <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <CuiFlexGroup direction='column'>
          <div style={{ maxWidth: '760px' }}>
            <CuiText size='titleDisplay'>Make the most of your Stanford experience.</CuiText>
          </div>
          <CuiSpacer size='l' />
          <div style={{ maxWidth: '564px' }}>
            <CuiText size='paragraphDisplay'>
              Carta helps students make data-driven decisions to craft their educational paths.
            </CuiText>
          </div>
        </CuiFlexGroup>
        <CuiSpacer size='l' />
        <img src={explore} alt='Explore' style={{ maxWidth: '760px', marginBottom: '-16px' }} />
      </div>
    </BackgroundWrapper>
  )
}

export const LandingPage: FunctionComponent<PropsWithChildren<unknown>> = () => {
  return (
    <CuiFlexGroup direction='column' gutterSizeVertical='s'>
      <div style={{ width: '100%' }}>
        <StaticNavbar style='light' />
        <Hero />
        <Facts />
      </div>
    </CuiFlexGroup>
  )
}
