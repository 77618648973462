import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import { CuiButton, CuiFlexGroup, CuiIcon, CuiLink, CuiSpacer, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import styled from 'styled-components'

import { StaticNavbar } from '../LandingPage/StaticNavbar'

const GradientSection = styled.div`
  background: linear-gradient(66deg, ${palette.brandMain70} -35.27%, #b71c1c 87.78%);
`

const ApplyBanner = styled.div`
  color: white;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 144px;
`

const OpenRolesRoot = styled.div`
  background: ${palette.neutral0};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 96px;
  padding-top: 96px;
  gap: 60px;
`

const RoleRoot = styled.div`
  background-color: white;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  max-width: 466px;
  gap: 12px;
`

const WhatYouGetRoot = styled.div`
  background-color: ${palette.neutral90};
  padding: 96px 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
`

const WhatYouGet = styled.div`
  background: ${palette.white};
  width: 280px;
  border-radius: 12px;
  padding: 32px;
`

interface ContentColumnProps {
  bgcolor?: string
}

const ContentColumn = styled.div<ContentColumnProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 128px;
  background-color: ${({ bgcolor }) => bgcolor ?? palette.white};
  gap: 20px;
`

const RequirementsRoot = styled.div`
  display: flex;
  flex-direction: row;
  height: 518px;
  align-items: stretch;
`

type WhatYouGetProps = { title: string; description: string }

const Stat: FunctionComponent<PropsWithChildren<WhatYouGetProps>> = ({ title, description }) => {
  return (
    <WhatYouGet>
      <CuiText as='div' size='title2' color='accent'>
        {title}
      </CuiText>
      <CuiSpacer size='s' />
      <CuiText>{description}</CuiText>
    </WhatYouGet>
  )
}

const Apply: FunctionComponent = () => {
  return (
    <ApplyBanner>
      <div style={{ maxWidth: '760px' }}>
        <CuiText color='inherit' size='title2'>
          JOIN OUR TEAM
        </CuiText>
        <CuiText color='inherit' size='titleDisplay'>
          Be a part of something big.
        </CuiText>
      </div>
      <CuiSpacer size='l' />
    </ApplyBanner>
  )
}

type RoleProps = { title: string; description: string; isClosed: boolean }

const Role: FunctionComponent<PropsWithChildren<RoleProps>> = ({ title, description, isClosed }) => {
  return (
    <RoleRoot>
      <CuiText size='title2'>{title}</CuiText>
      <CuiSpacer size='xs' />
      <CuiText>{description}</CuiText>
      <CuiSpacer />
      {!isClosed && (
        <CuiButton color='accent'>
          <CuiText color='textOnDarkBackground'>Apply</CuiText>
        </CuiButton>
      )}
    </RoleRoot>
  )
}

type OpenRolesProps = { isClosed: boolean }

const OpenRoles: FunctionComponent<PropsWithChildren<OpenRolesProps>> = ({ isClosed }) => {
  return (
    <OpenRolesRoot>
      {isClosed ? (
        <CuiText size='title1'>Our roles</CuiText>
      ) : (
        <CuiText size='title1'>Our open roles</CuiText>
      )}
      <CuiFlexGroup justifyContent='spaceBetween' gutterSizeHorizontal='xl'>
        <Role
          isClosed={isClosed}
          title='Software Engineer'
          description='Building a platform that can handle thousands of student points at scale with speed on a responsive frontend.'
        />
        <Role
          isClosed={isClosed}
          title='Product Designer'
          description='Define the user experience, interaction design, and visuals that help students like you plan their academic journey.'
        />
      </CuiFlexGroup>
    </OpenRolesRoot>
  )
}

const WhatYouGetBanner: FunctionComponent = () => {
  return (
    <WhatYouGetRoot>
      <CuiText size='title1' color='textOnDarkBackground'>
        What You Get
      </CuiText>
      <CuiFlexGroup alignItems='flexStart' gutterSize='xl'>
        <Stat title='High impact work' description='95% of the student population uses Carta.' />
        <Stat
          title='Unit compensation'
          description='Working on Carta is a class with up to 5 units of independent study credit.'
        />
        <Stat
          title='Industry level experience'
          description='Engineering and design following industry best practices.'
        />
        <Stat
          title='Collaborative atmosphere'
          description='Work with a talented agile team of 10+ people. Our alum go on to Google, Apple, Meta, and more.'
        />
      </CuiFlexGroup>
    </WhatYouGetRoot>
  )
}

type RequirementsProps = { isClosed: boolean }

const Requirements: FunctionComponent<PropsWithChildren<RequirementsProps>> = ({ isClosed }) => {
  return (
    <Fragment>
      <RequirementsRoot>
        <ContentColumn bgcolor={palette.neutral0}>
          <CuiText size='title1'>Applicant requirements</CuiText>
          <CuiFlexGroup gutterSize='m' alignItems='flexStart'>
            <CuiIcon type='recent' size='l' color='accent' />
            <CuiText>
              Able to work{' '}
              <CuiText as='span' size='title3'>
                15 hours / unit{' '}
              </CuiText>
              over the course of a quarter by attending{' '}
              <CuiText as='span' size='title3'>
                in-person work{' '}
              </CuiText>
              sessions each week.
            </CuiText>
          </CuiFlexGroup>
          <CuiFlexGroup gutterSize='m' alignItems='flexStart'>
            <CuiIcon type='building-project' size='l' color='accent' />
            <CuiText>
              Some experience in{' '}
              <CuiText as='span' size='title3'>
                frontend / backend{' '}
              </CuiText>
              web development or{' '}
              <CuiText as='span' size='title3'>
                UI / UX design
              </CuiText>
              , and a desire to learn more.
            </CuiText>
          </CuiFlexGroup>
        </ContentColumn>
        <ContentColumn bgcolor='#FAFAFA'>
          <CuiText size='title1'>We especially look for...</CuiText>
          <CuiFlexGroup gutterSize='l' alignItems='center'>
            <CuiIcon type='calendar' size='m' color='accent' />
            <CuiText>
              Those interested in working on Carta for{' '}
              <CuiText as='span' size='title3'>
                2+ quarters
              </CuiText>
            </CuiText>
          </CuiFlexGroup>
          <CuiFlexGroup gutterSize='l' alignItems='center'>
            <CuiIcon type='check' size='m' color='accent' />
            <CuiText>
              <CuiText as='span' size='title3'>
                Freshman{' '}
              </CuiText>
              or{' '}
              <CuiText as='span' size='title3'>
                sophomore{' '}
              </CuiText>{' '}
              Stanford undergrads
            </CuiText>
          </CuiFlexGroup>
        </ContentColumn>
      </RequirementsRoot>
      <RequirementsRoot>
        <ContentColumn>
          {isClosed ? (
            <Fragment>
              <CuiText size='title1'>Applications are closed.</CuiText>
              <CuiText size='title2'>Carta applications will open up at the end of Fall Quarter</CuiText>
            </Fragment>
          ) : (
            <Fragment>
              <CuiText size='title1'>Apply now!</CuiText>
              <CuiText size='title2'>
                We will email applicants near the end of the quarter with follow up information
              </CuiText>
            </Fragment>
          )}
          <CuiText>
            Your work is compensated with four units of independent study under CS 199, 399, or 195 with
            John Mitchell. Senior project opportunities are also available.
          </CuiText>
        </ContentColumn>
        <ContentColumn>
          <RoleRoot>
            <CuiText size='title1'>Questions?</CuiText>
            <CuiSpacer size='s' />
            <CuiText>
              We’re here to help. Contact{' '}
              <CuiLink href='mailto:hhannah@stanford.edu'>hhannah@stanford.edu</CuiLink> or{' '}
              <CuiLink href='mailto:mkhadka@stanford.edu'>mkhadka@stanford.edu</CuiLink> with any
              questions you have about joining our team.
            </CuiText>
          </RoleRoot>
        </ContentColumn>
      </RequirementsRoot>
    </Fragment>
  )
}

export const ApplyPage = () => {
  const isClosed = true

  return (
    <div>
      <GradientSection>
        <StaticNavbar style='gradient' />
        <Apply />
        <OpenRoles isClosed={isClosed} />
        <WhatYouGetBanner />
        <Requirements isClosed={isClosed} />
      </GradientSection>
    </div>
  )
}
