import { CuiIcon, CuiText } from 'src/cui/components'
import styled from 'styled-components'

const ProfileToggleWrapper = styled.div`
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
  border-color: ${({ theme }) => theme.cuiColors.text};
  border-width: 1px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover02};
  }
`

const ProfileToggleCircle = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme.cuiColors.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  aspect-ratio: 1;
  width: 100%;
`

export const loggedInUser = (
  <ProfileToggleWrapper>
    <ProfileToggleCircle>
      <CuiText size='title4' color='textOnDarkBackground'>
        JS
      </CuiText>
    </ProfileToggleCircle>
    <CuiIcon type='caret-down' size='l' />
  </ProfileToggleWrapper>
)
