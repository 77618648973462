import { type FunctionComponent } from 'react'
import { CuiFlexGroup, CuiSpacer, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import styled from 'styled-components'

import research from './images/about/research.png'

const ResearchRoot = styled.div`
  padding: 128px 144px;
  background: ${palette.white};
`

const ContentColumn = styled.div`
  flex: 1;
`

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
`

export const Research: FunctionComponent = () => {
  return (
    <ResearchRoot>
      <CuiFlexGroup direction='row'>
        <ContentColumn>
          <CuiText size='title1'>Rooted in research</CuiText>
          <CuiSpacer size='l' />
          <CuiFlexGroup direction='column' alignItems='flexStart'>
            <CuiText size='paragraph1'>
              Carta began as an interdisciplinary research project in the CartaLab.
            </CuiText>
            <CuiSpacer size='l' />
            <CuiText size='paragraph1'>
              Carta was created as a platform to learn about college pathways. The information gathered
              from student engagement with the platform enabled research to understand how students
              consider, choose, and sequence courses — and how these actions are related to other aspects
              of students’ lives before, during, and after Stanford.
            </CuiText>
          </CuiFlexGroup>
        </ContentColumn>
        <ImageColumn>
          <img src={research} alt='research' />
        </ImageColumn>
      </CuiFlexGroup>
    </ResearchRoot>
  )
}
