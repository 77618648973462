import {
  type MakeGenerics,
  Outlet,
  type Search,
  useMatches,
  useNavigate,
  useSearch as useSearchParams,
} from '@tanstack/react-location'
import { Fragment, type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react'
import { useWhoAmI } from 'src/api/auth'
import { useCourse, useInfiniteCourses, useInfiniteSearchCourses } from 'src/api/course'
import { useAppDispatch } from 'src/AppRoot/hooks'
import { setSunetId } from 'src/AppRoot/store'
import {
  CuiFlexGroup,
  CuiInfiniteLoader,
  CuiLoadingSpinner,
  CuiPad,
  CuiScrollArea,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import { useIsMobile } from 'src/utils/mediaQuery'
import { useMatchParams } from 'src/utils/useMatchParams'
import styled from 'styled-components'

import { CourseCard, CourseCardSkeleton } from './CourseCard'
import { CourseDetail } from './CourseDetail'
import type { HandleFilterParams, SearchType, SortOptions } from './CourseFiltersGroup'
import { CourseFiltersGroup } from './CourseFiltersGroup'

const CoursesRightBorder = styled.div`
  border-right: 1px solid ${({ theme }) => theme.cuiColors.lightShade};
`
const GridWrapper = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
`
const LoadingCourses: FunctionComponent<PropsWithChildren<{ hasTopBorder?: boolean }>> = ({
  hasTopBorder = false,
}) => {
  return (
    <Fragment>
      {hasTopBorder && <CuiSpacer as='hr' size='none' />}

      <CourseCardSkeleton />

      <CuiSpacer as='hr' size='none' />

      <CourseCardSkeleton />

      <CuiSpacer as='hr' size='none' />

      <CourseCardSkeleton />
    </Fragment>
  )
}

const CourseList: FunctionComponent<
  PropsWithChildren<{
    infiniteCourses: ReturnType<typeof useInfiniteCourses | typeof useInfiniteSearchCourses>
    sortOption?: SortOptions
  }>
> = ({ infiniteCourses, sortOption }) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = infiniteCourses
  const sortedCourses = [...(data?.courses ?? [])]

  if (sortOption === 'Department') {
    sortedCourses.sort((a, b) => a.courseCode.subject.localeCompare(b.courseCode.subject))
  } else if (sortOption === 'Units') {
    sortedCourses.sort((a, b) => a.units.minimum.toString().localeCompare(b.units.minimum.toString()))
  }

  return (
    <Fragment>
      {sortedCourses.map((course, idx) => (
        <Fragment key={course.id}>
          {idx > 0 && <CuiSpacer as='hr' size='none' />}

          <CourseCard course={course} />
        </Fragment>
      ))}

      <CuiInfiniteLoader
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        loader={<LoadingCourses hasTopBorder={isFetchingNextPage} />}
        fetchNextPage={fetchNextPage}
      />
    </Fragment>
  )
}

export const HomeImpl: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { mutate: fetchWhoAmI, data } = useWhoAmI()
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchWhoAmI()
  }, [fetchWhoAmI])

  useEffect(() => {
    if (data?.sunetID) {
      dispatch(setSunetId(data.sunetID))
    }
  }, [data, dispatch])

  const searchOptions = useSearchParams() as Partial<Search<String>>

  const hasSearchOptions = Object.keys(searchOptions).length > 0

  const infiniteCourses = useInfiniteCourses({ year: 2025 }, {}, { enabled: !hasSearchOptions })

  const searchInfiniteCourses = useInfiniteSearchCourses(
    {
      year: 2025,
    },
    searchOptions,
    { enabled: hasSearchOptions }
  )
  const isMobile = useIsMobile()

  const matches = useMatches()
  const matchesCourseDetail = matches.some((match) => match.route.path === ':courseId')

  type SearchRouteParams = MakeGenerics<{
    Search: SearchType
  }>

  const selectedOptions = useSearchParams<SearchRouteParams>()

  const navigate = useNavigate<SearchRouteParams>()

  const [sortOption, setSortOption] = useState<SortOptions>('Default')

  const filters = (
    <Fragment>
      <CuiPad horizontalSize='m' topSize='l' bottomSize='m'>
        <CuiText size='title2'>Search Results for {searchOptions.query}</CuiText>
        <CourseFiltersGroup
          selectedOptions={selectedOptions}
          handleFilter={handleFilter}
          handleSort={handleSort}
        />
      </CuiPad>
      <CuiSpacer as='hr' size='none' />
    </Fragment>
  )

  if (isMobile) {
    if (matchesCourseDetail) {
      return (
        <CuiPad size='m'>
          <Outlet />
        </CuiPad>
      )
    }

    return (
      <Fragment>
        {filters}

        <CourseList
          infiniteCourses={hasSearchOptions ? searchInfiniteCourses : infiniteCourses}
          sortOption={sortOption}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      {filters}

      <GridWrapper>
        <CuiScrollArea>
          <CoursesRightBorder>
            <CuiPad size='m'>
              <CourseList
                infiniteCourses={hasSearchOptions ? searchInfiniteCourses : infiniteCourses}
                sortOption={sortOption}
              />
            </CuiPad>
          </CoursesRightBorder>
        </CuiScrollArea>
        <CuiScrollArea>
          <CuiPad verticalSize='m' horizontalSize='l'>
            <Outlet />
          </CuiPad>
        </CuiScrollArea>
      </GridWrapper>
    </Fragment>
  )

  function handleSort(sortOption: SortOptions) {
    setSortOption(sortOption)
  }

  function handleFilter(filterOutput: HandleFilterParams) {
    const urlFilters = filterOutput.searchFilters.length !== 0 ? filterOutput.searchFilters : undefined

    navigate({
      search: (prevSearchParams) => ({
        ...prevSearchParams,
        [filterOutput.filterName]: urlFilters,
      }),
    })
  }
}

const CourseDetailImpl: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { courseId } = useMatchParams<{ courseId: string }>()
  const { data: course, isLoading, error } = useCourse({ id: courseId, year: 2025 })

  if (isLoading) {
    // TODO vertically center
    return (
      <CuiFlexGroup justifyContent='center' alignItems='center'>
        <CuiLoadingSpinner />
      </CuiFlexGroup>
    )
  }

  if (error ?? !course) {
    // TODO replace with error component
    return <Fragment>ERROR!</Fragment>
  }

  return <CourseDetail course={course} />
}

export const Home = {
  Home: HomeImpl,
  CourseDetail: CourseDetailImpl,
}
