import { type FunctionComponent, type PropsWithChildren } from 'react'
import { CuiSpacer, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import styled from 'styled-components'

import { StaticNavbar } from '../LandingPage/StaticNavbar'
import { Team } from './Team'

const GradientSection = styled.div`
  background: linear-gradient(66deg, ${palette.brandMain70} -35.27%, #b71c1c 87.78%);
`

const AboutBanner = styled.div`
  color: white;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 144px;
`

const About: FunctionComponent = () => {
  return (
    <AboutBanner>
      <div style={{ maxWidth: '720px' }}>
        <CuiText color='inherit' size='title2'>
          ABOUT US
        </CuiText>
        <CuiText color='inherit' size='titleDisplay'>
          Our team of student engineers and designers bring Carta to life.
        </CuiText>
      </div>
      <CuiSpacer size='l' />
    </AboutBanner>
  )
}

export const AboutPage: FunctionComponent<PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <GradientSection>
        <StaticNavbar style='gradient' />
        <About />
        <Team />
      </GradientSection>
    </div>
  )
}
